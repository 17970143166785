
import moment,{ Moment } from 'moment'
import { Options, Vue } from 'vue-class-component';
import { Emit, Provide, Watch } from 'vue-property-decorator';
import { list } from '@/api/order'
import { deepCopy } from '@/untils/until'
import { message, Modal } from "ant-design-vue/es";
import {
  updateGps
} from "../../api/platform";

import { ColumnProps } from 'ant-design-vue/es/table/interface';
type Key = ColumnProps['key'];
interface ISearchForm {
  pageNum: number,
  pageSize: number,
  ddbh: string,
  fbfnsrmc: string,
  cycph: string,
  qsfwsj: string,
  fwfxm: string,
  state: number
}
@Options({
  components: {
  },
})
export default class PushOrder extends Vue {
  @Provide() columns: any =[
  {
    title: '序号',
    customRender: ({index} :any)=> {
      return this.searchForm.pageNum > 1 ? `${(this.searchForm.pageNum -1)*(this.searchForm.pageSize)+index+1}`:index+1
    },
    width: 80
  },
  {
    title: '订单编号',
    className: 'column-money',
    dataIndex: 'ddbh',
    key: 'ddbh',
    width: 155,
    slots: { customRender: 'number' },
  },
  {
    title: '发布方企业名称',
    dataIndex: 'fbfnsrmc',
    width: 120,
    ellipsis: true,
  },
  {
    title: '状态',
    dataIndex: 'state',
    width: 80,
    slots: { customRender: 'states' },
  },
  {
    title: '异常原因',
    dataIndex: 'specialCause',
    width: 100,
    slots: { customRender: 'specialCause' },
    ellipsis: true,
  },
  {
    title: '起始运输时间',
    dataIndex: 'qsfwsj',
    width: 100,
    ellipsis: true,
  },
  {
    title: '货物名称',
    dataIndex: 'hwmc',
    width: 100,
    ellipsis: true,
  },
  {
    title: '货物重量（吨）',
    dataIndex: 'hwzl',
    width: 120,
  },
  {
    title: '（车主）姓名',
    dataIndex: 'fwfxm',
    width: 120,
  },
  {
    title: '承运车牌号',
    dataIndex: 'cycph',
    width: 120,
  },
  {
    title: '结束运输时间',
    dataIndex: 'jsfwsj',
    width: 120,
    ellipsis: true,
  },
  {
    title: '提现付款时间',
    dataIndex: 'jssj',
    width: 120,
    ellipsis: true,
  },
  {
      title: "操作",
      width: 100,
      dataIndex: "operation",
      slots: { customRender: "operation" },
    },
  ];
  @Provide() total: number =0;
  @Provide() data: any =[];
  @Provide() searchForm: ISearchForm = {
    pageNum: 1,
    pageSize: 10,
    ddbh: '',
    fbfnsrmc: '',
    cycph: '',
    qsfwsj: '',
    fwfxm: '',
    state: 6
  }
  @Provide() selectedRowKeys:Key[] = []
  visible=false;
  indent="";
  ids="";
  mounted() {
    this.list()
  }
  
  onSelectChange(selectedRowKeys: Key[]) {
    this.selectedRowKeys = selectedRowKeys
  }
  onChange(pageNumber: number) :void {
    this.searchForm.pageNum = pageNumber
    this.list()
  }
    handleCancel() {
      
      this.visible = false;
    }
    showModal(id: any) {
      console.log(id);
      this.ids=id
      this.visible = true;
    }
    handleOk() {
      if (this.indent) {
        console.log(this.indent);
      updateGps({
        id:this.ids,
        ddbh:this.indent
      }).then((res:any)=>{
        if(res.code==200){
          message.success('修改成功');
          this.list()
        }
        
      })
      this.visible = false;
      }else{
        
        message.error("订单号不能为空！")
        
      }
      
    }
  list() :void {
    const newSearch = deepCopy(this.searchForm)
    newSearch.qsfwsj = this.searchForm.qsfwsj ? moment(this.searchForm.qsfwsj).format('YYYY-MM-DD'): ''
    list(newSearch).then((res: any)=> {
      if (res.code == 200) {
        this.total = res.data.total
        this.data = res.data.list
      }
    })
  }
}
